@use "../../scss/" as *;

.modalContainerBg{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background: rgba(5, 13, 51, 0.05);
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: .2s ease-in-out;

    &.active{
        opacity: 1;
        pointer-events: all;
    }
}

.modal{
    max-width: 640rem;
    max-height: 740rem;
    width: 100%;
    border-radius: 12rem;
    overflow: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 101;
    transform: translate(-50%, -50%) scale(0);
    background-color: $white;
    padding: 32rem;
    border-radius: 24rem;
    opacity: 0;
    scrollbar-width: none; /* для скрытия скроллбара в Firefox */
    transition: .3s ease-in-out;
    &.active{
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);

        @include mobile{
            transform: translate(-50%, -50%) scale(.9);
        }
    }

    /* Скрытие скроллбара */
    &::-webkit-scrollbar {
        width: 0;  /* для скрытия скроллбара в Safari/Chrome */
    }


    .imgItem{
        width: 100%;
        height: 500rem;
        overflow: hidden;
        object-fit: cover;
    }

    .slick-dots{
        bottom: 10rem;
    }

    .modalContainerGrid{
        display: flex;
        grid-gap: 16rem;
        flex-direction: column;
    }
}