@use "../../scss/" as *;

.reportCotainer{
    padding: 12rem;
    display: flex;
    flex-direction: column;
    gap: 8rem;

    .constructionInfoTitle{
        @include fnt(28, 36, $weight:700, $color: $black, $font: $PTRootUI)
    }
    .constructionInfoAddress{
        @include fnt(14, 20, $weight:400, $color: $txt-color, $font: $PTRootUI)
    }

    
}

.formReport{
    max-width: 320rem;
    display: flex;
    flex-direction: column;
    gap: 24rem;
}

.containerReportForm{
   
    display: flex;
    flex-direction: column;
    gap: 8rem;
    .constructionInfoSubTitle{
        @include fnt(20, 24, $weight:500, $color: $black, $font: $PTRootUI)
    }
    
}

.uploadWhiteButton{
    width: fit-content;
    padding: 6rem 12rem;

    background-color: $white;
    border: 1rem solid $blue-100;
    border-radius: 8rem;

    @include fnt(14, 20, $weight:500, $color: $blue-700, $font: $PTRootUI);

    &:hover{
        background-color: $gray-100;
    }
}
.saveButton{
    width: fit-content;
    padding: 8rem 14rem 8rem 8rem !important;

    background-color: #00CCCC;
    border-radius: 8rem;

    @include fnt(14, 20, $weight:500, $color: $white, $font: $PTRootUI);

    &:hover{
        background-color: $gray-100;
    }
}

.dateDeadline{
    @include fnt(14, 20, $weight:400, $color: $txt-color, $font: $PTRootUI)
}

.modalSms{
    max-width: 384rem;

    .modalSmsContainer{
        display: flex;
        flex-direction: column;
        gap: 16rem;

        h1{
            @include fnt(24, 32, $weight:700, $color: $txt-color, $font: $PTRootUI);
        }

        // p{
        //     @include fnt(14, 20, $weight:400, $color: $text-gray, $font: $fontText);
        // }

        .getCodeText{
            @include fnt(12, 20, $weight:500, $color: $blue);
            margin-left: 12rem;
            cursor: pointer;
        }
    }

    .modalContainerButton{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8rem;
        margin-top: 12rem;
    }

    .buttonRepeat{
        @include fnt(14, 20, $weight:400, $color: $text-gray, $font: $fontText);
        margin-left: 12rem;
    }
}

.fileContainer{
    display: flex;
    align-items: center;
    gap: 4rem;
   
    @include fnt(14, 20, $weight:400, $color: $text-gray, $font: $fontText);

    p{
        width: 300rem;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        overflow: hidden;
    }

    &:hover{
      p{
        color: $blue-500;
      }
    }
}

.saveButtonFixed{
    position: fixed;
    bottom: 30rem;
    right: 30rem;

    background-color: #00CCCC;
    width: 200rem;
    height: 50rem;
    border-radius: 14rem;
}

.containerButton{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8rem;
}

.subtitleModal{
    @include fnt(14, 20, $weight:400, $color: $text-gray, $font: $fontText);
}

.closeModal{
    background-color: inherit;
    border: 1rem solid #00103D1F;

    @include fnt(14, 20, $weight:500, $color: $txt-color, $font: $PTRootUI);

    &:hover{
        background-color: $white;
    }
}
.completeModal{
    background-color: $error-500;

    @include fnt(14, 20, $weight:500, $color: $white, $font: $PTRootUI);

    &:hover{
        background-color: $red;
    }
}

.successReport{
    display: flex;
    align-items: center;
    gap: 4rem;
    @include fnt(16, 24, $weight:500, $color: $blue-500, $font: $PTRootUI);
}