@use "../../scss/" as *;

.containerControl{
    margin-top: 8rem;
}

.cardControl{
    width: 100%;
    background-color: $gray-40;
    border-radius: 12rem;
    padding: 16rem;

    display: flex;
    flex-direction: column;
    gap: 12rem;
    cursor: pointer;

    &.notify {
        background-color: rgba(255, 212, 0, 0.3);
    }

    .cardControlFlex{
        display: flex;
        justify-content: space-between;

        .userContainer{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        .cardContainerName{
            @include fnt(14, 20, $weight:700, $font: $PTRootUI, $color: $txt-color)
        }
        .cardAddress{
            @include fnt(14, 20, $weight:400, $font: $PTRootUI, $color: $txt-color)
        }
        .titleInfo{
            text-align: end;
            @include fnt(14, 20, $weight:400, $font: $PTRootUI, $color: $text-gray)
        }
        .fioResponsible{
            
            @include fnt(14, 20, $weight:700, $font: $PTRootUI, $color: $txt-color)
        }
        .telResponsible{
            text-align: end;
            @include fnt(14, 20, $weight:400, $font: $PTRootUI, $color: $txt-color)
        }
        .contractorTitle{
            @include fnt(14, 20, $weight:700, $font: $PTRootUI, $color: $txt-color)
        }
      
    }
    .cardControlFlexEnd{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .titleInfo{
            @include fnt(14, 20, $weight:400, $font: $PTRootUI, $color: $text-gray)
        }
        .fioResponsible{
            @include fnt(14, 20, $weight:700, $font: $PTRootUI, $color: $txt-color)
        }
        .telResponsible{
            @include fnt(14, 20, $weight:400, $font: $PTRootUI, $color: $txt-color)
        }
        .contractorTitle{
            @include fnt(14, 20, $weight:700, $font: $PTRootUI, $color: $txt-color)
        }

        .cardContainerStages{
            display: flex;
            gap: 8rem;

            .cardStages{
                @include fnt(14, 20, $weight: 700, $font: $PTRootUI, $color: $txt-color)
            }
            .cardDate{
                @include fnt(14, 20, $weight: 400, $font: $PTRootUI, $color: $text-gray)
            }
        }
    }

  
}

.stagesTitle{
    @include fnt(14, 20, $weight:400, $color: $text-gray, $font: $PTRootUI);
}

.stagesCardText{
    @include fnt(14, 20, $weight:700, $color: $txt-color, $font: $PTRootUI);

    b{
        @include fnt(14, 20, $weight:400, $color: $text-gray, $font: $PTRootUI);
    }
}