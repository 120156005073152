@use "../../scss/" as *;

.backGray{
    display: flex;
    flex-direction: column;
    margin-bottom: 16rem;
}

.headerFounder{
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1{
        @include fnt(20, 24, $weight:700, $color: $txt-color, $font: $PTRootUI);
    }

    .closeButton{
        cursor: pointer;
        transition: .3s linear;

        &:hover{
        background-color: $white;
        border-radius: 4rem;

    }
    }
}

.addButton{
    padding: 8rem 14rem 8rem 8rem !important;
    border-radius: 8rem;
    background-color: $blue-500;
    margin-top: 24rem;
    @include fnt(14, 20, $weight:500, $color: $white, $font: $PTRootUI);

    object{
        width: 20rem;
        height: 20rem;
        margin-right: 6rem;
    }
}

// .whiteButton{
//     background-color: $blue-500;
//     padding: 10rem !important;
//     border: 1rem solid $media-200;
//     box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
//     border-radius: 8rem;

//    @include fnt(14, 20, $weight:500, $color: $white, $font: $fontText);
 

//     &:hover{
//         background-color: $blue-700;
//     }

//     object{
//         width: 20rem;
//         height: 20rem;
//         margin-right: 4rem;
//     }
// }