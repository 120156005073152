@use "../../scss/" as *;

.bgLogin{
    width: 100%;
    height: 100%;
    object-fit:cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -100;
}

.containerLoginPage{
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16rem;

    h4{
        @include fnt(14, 20, $weight:400, $color: $white, $font: $fontText);
        width: 219rem;
        text-align: center;
    }
}