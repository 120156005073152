@use "../../scss/" as *;

.headerLoginContainer{
    padding: 16rem;
    position: fixed;

    .containerLogoLogin{
        display: flex;
        align-items: flex-end;
        gap: 14rem;

        .bikLogoLogin{
            height: 40rem;
            object-fit: cover;
        }

        h1{
            @include fnt(17.5, 17.5, $weight:400, $color: $white, $font: $fontText);
            width: 126rem;
        }
    }
}