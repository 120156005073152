@use "../../scss/" as *;

.containerLogin{
    width: 304rem;
    padding: 24rem 32rem;
    background-color: $white;

    display: flex;
    flex-direction: column;
    gap: 16rem;
    border-radius: 16rem;
    box-shadow: rgba(0, 16, 61, 0.16);
    transition: .3s ease-in-out;

    h1{
        @include fnt(24, 32, $weight:700)
    }

    .buttonLogin{
        margin-top: 12rem;
        border-radius: 8rem;
        height: 36rem;
        background-color: $blue;
        padding: 8rem;
    }

    .getCodeText{
        @include fnt(12, 20, $weight:500, $color: $blue);
        margin-left: 12rem;
        cursor: pointer;
    }
}