@use "../../scss/" as *;

.formReport{
    padding-bottom: 12rem;
    border-bottom: 1rem solid $blue-500;
}

.disabled{
    opacity: .5;
    margin-bottom: 14rem;
}

.errorComment{
    background-color: $error-50;
    border: 1rem solid $error-300;
    border-radius: 8rem;
    padding: 8rem 12rem;
    max-width: 688rem;

    .textErrorComment{
        @include fnt(14, 20, $weight: 400, $font: $PTRootUI, $color: $error-500);
    }

    .titleErrorComment{
        @include fnt(14, 20, $weight: 700, $font: $PTRootUI, $color: $error-500);
    }
}

.saveButtonReport{
    width: fit-content;
    padding: 8rem 14rem;
    background-color: #00CCCC;
    border-radius: 8rem;
    @include fnt(14, 20, $weight: 500, $font: $PTRootUI, $color: $white);
}

.saveButtonClose{
    padding: 8rem 14rem;
    border: 1rem solid $error-500;
    background-color: $white;
    border-radius: 8rem;
    @include fnt(14, 20, $weight: 500, $font: $PTRootUI, $color: $error-500);

    &:hover{
        background-color: $error-500;
        color:$white;
    }
}
.saveButtonComplete{
    padding: 8rem 14rem;
    
    border-radius: 8rem;
    @include fnt(14, 20, $weight: 500, $font: $PTRootUI, $color: $white);
}

.containerMark{
   background-color: #F3F3F5;
    padding: 16rem;
    display: flex;
    flex-direction: column;
    gap: 8rem;
    border-radius: 12rem;

    .containerToggle{
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 8rem;
        border-radius: 12rem;
        border: 1rem solid $gray-300;
    }

    h1{
        @include fnt(20, 24, $weight: 500, $font: $PTRootUI, $color: $txt-color);
    }
}