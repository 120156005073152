@use "../../scss/" as *;

.constructionInfoCotainer{
    padding: 12rem;
    display: flex;
    flex-direction: column;
    gap: 8rem;

    @include mobile{
        padding: 0 12rem;
    }

    .constructionInfoTitle{
        @include fnt(28, 36, $weight:700, $color: $black, $font: $PTRootUI)
    }
    .constructionInfoAddress{
        @include fnt(14, 20, $weight:400, $color: $txt-color, $font: $PTRootUI)
    }

    .constructionInfoContainerPeople{
        display: flex;
        gap: 24rem;

        @include mobile{
            flex-direction: column;
        }

        .titleInfoPeople{
            @include fnt(14, 20, $weight:400, $color: $text-gray, $font: $PTRootUI)
        }
        .nameInfoPeople{
            @include fnt(14, 20, $weight:700, $color: $txt-color, $font: $PTRootUI)
        }
        .phoneInfoPeople{
            @include fnt(14, 20, $weight:400, $color: $txt-color, $font: $PTRootUI)
        }
    }

    .numberConstruction{
        @include fnt(16, 24, $weight:400, $color: $txt-color, $font: $PTRootUI);

        span{
            @include fnt(16, 24, $weight:700, $color: $txt-color, $font: $PTRootUI)
        }
    }

    .containerStage{
        display: flex;
        flex-direction: column;
        gap: 12rem;
    }

    .containerStatus{
        display: flex;
        gap: 4rem;
    }

    .stageContainer{
        width: 100%;
        background-color: $gray-40;
        border-radius: 12rem;
        padding: 12rem 16rem;
        cursor: pointer;
        transition: .3s ease-in-out;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mobile{
            flex-direction: column;
            align-items: flex-start;
            gap: 4rem;
        }

        &:hover{
            background-color: $gray-50;
        }

        h1{
            @include fnt(14, 24, $weight:700, $color: $txt-color, $font: $PTRootUI)
        }

        p{
            @include fnt(14, 16, $weight:400, $color: $txt-color, $font: $PTRootUI)
        }

        
.isComplete{
    @include fnt(14, 20, $weight:500, $color:$green-400, $font: $PTRootUI);
    @include mobile{
        text-align: center;
        width: 100%;
    }
}
.isOverdue{
    @include fnt(14, 20, $weight:500, $color:$error-500, $font: $PTRootUI);
    @include mobile{
        text-align: center;
        width: 100%;
    }
}

        .successStatus{
            background-color: $green-400;
            padding: 0 4rem;
            height: fit-content;
            @include fnt(12, 16, $weight:700, $color:$white, $font: $PTRootUI);
            width: fit-content;
        }
        .errorStatus{
            background-color: $error-500;
            padding: 0 4rem;
            height: fit-content;
            @include fnt(12, 16, $weight:700, $color:$white, $font: $PTRootUI);
            width: fit-content;
        }
    }
}

.whiteButton{
    background-color: inherit;
    @include fnt(14, 20, $weight:500, $color:$blue-500, $font: $PTRootUI);
    border: 1rem solid $blue-500;
    border-radius: 8rem;

    &:hover{
        color: $white
    }
}
.blueButton{
    background-color: $blue-500;
    @include fnt(14, 20, $weight:500, $color:$white, $font: $PTRootUI);
    width: fit-content;
    border-radius: 8rem;
}


.containerInfoMark{
    display: flex;
    align-items: center;
    gap: 4rem;
    padding-top: 8rem;
    margin-top: 8rem;
    border-top: 1rem solid $gray-300;
    .infoMark{
        display: flex;
        align-items: center;
        gap: 4rem;
        border-radius: 12rem;
        outline: 1rem solid $gray-300;
        padding: 2rem 8rem;
        background-color: $white;
      

        @include fnt(14, 20, $weight:700, $color:$txt-color, $font: $PTRootUI);

        p{
            @include fnt(12, 20, $weight:500, $color:$text-gray, $font: $fontText);

            &.red{
                color: $error-500;
            }
            &.green{
                color: #8c0;
            }
            &.gray{
                color: $text-gray;
            }
        }
    }
}