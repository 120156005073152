@use "../../scss/" as *;

.formContainer{
    max-width: 356rem;
    padding: 24rem 16rem;
    display: flex;
    flex-direction: column;
    gap: 12rem;

    .titleForm{
        @include fnt(28, 36, $weight:700, $color: $txt-color, $font: $PTRootUI);
        margin-bottom: 12rem;

        &__line{
            @include fnt(28, 36, $weight:700, $color: $txt-color, $font: $PTRootUI);
            border-bottom: 1rem solid #BFC1C7;
            padding-bottom: 12rem;
        }
    }
}

.titleEnd{
    @include fnt(20, 24, $weight:500, $color: $txt-color, $font: $PTRootUI);
    border-bottom: 1rem solid #BFC1C7;
    padding-bottom: 12rem;
    margin-top: 12rem;
}

.responsibleContainer{
    display: flex;
    flex-direction: column;
    gap: 12rem;
    margin-top: 12rem;

    h1{
        @include fnt(20, 24, $weight:500, $color: $txt-color, $font: $PTRootUI);
    }
}